import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col, Button } from 'react-bootstrap';
import {
  InputAdornment,
  MenuItem,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { makeRequired, makeValidate, TextField, Select } from 'mui-rff';
import * as Yup from 'yup';
import styled from 'styled-components';

import { DIGIT_PATTERN } from '../constants/Regexp';

import Layout from '../components/layouts/layout';
import SEO from '../components/seo';
import ServiceHeading from '../components/layouts/serviceHeading';
import CtaSection from '../components/ctaSection';
import HelpCenterLayout from '../components/layouts/helpCenterLayout';
import FaqAccordion from '../components/faqAccordion';
import ContactSection from '../components/contactSection';
import RechargeNavLinks from '../components/rechargeNavLinks';

import RechargeImage from '../images/bg-recarga-cubacel.png';
import MobileImageSrc from '../images/mobile-app.png';
import EmoteImage from '../images/emotes-cubacel.png';

const ContainerWrapper = styled.div`
  @media (max-width: 767px) {
    // margin-top: 10rem;
  }
`;

const AdjustableCol = styled(Col)`
  @media (max-width: 767px) {
    // height: 14rem;
  }
`;

const MobileImage = styled.img`
  width: 80%;

  @media (max-width: 767px) {
    position: relative;
    top: -10rem;
  }
`;

const fakeAmounts = [
  {
    id: 1,
    title: '1000 CUP',
    description: 'Reciben 1000 CUP de saldo principal',
    value: '19.19',
    previousValue: '22.89',
  },
  {
    id: 2,
    title: '1000 CUP',
    description: 'Reciben 1000 CUP de saldo principal',
    value: '19.19',
    previousValue: '22.89',
  },
  {
    id: 3,
    title: '1000 CUP',
    description: 'Reciben 1000 CUP de saldo principal',
    value: '19.19',
    previousValue: '22.89',
  },
  {
    id: 4,
    title: '1000 CUP',
    description: 'Reciben 1000 CUP de saldo principal',
    value: '19.19',
    previousValue: '22.89',
  },
];

const faqLeft = [
  {
    title: '¿En qué tiendas puedo recargar personalmente?',
    description: 'Hello World',
  },
  {
    title: '¿Puedo recargar desde cualquier parte del mundo?',
    description: 'Hello World',
  },
  {
    title: '¿Cómo sé que le llegó la recarga a mi familiar o amigo?',
    description: 'Hello World',
  },
  {
    title: '¿Cómo mi familiar o amigo se entera que recibió el saldo?',
    description: 'Hello World',
  },
];

const faqRight = [
  {
    title: '¿Recargué un número equivocado, cómo puedo recuperar mi dinero?',
    description: 'Hello World',
  },
  {
    title: '¿Cómo puedo saber cuándo hay promoción de recargas?',
    description: 'Hello World',
  },
  {
    title: 'Mi familiar no recibió la recarga completa, solo el saldo.',
    description: 'Hello World',
  },
  {
    title: '¿Solo puedo recargar a números en Cuba?',
    description: 'Hello World',
  },
  {
    title: '¿Puedo pagar el plan prepago de mi celular en USA?',
    description: 'Hello World',
  },
];

const CubacelRechargePage = () => {
  const schema = Yup.object().shape({
    // Phone number validations
    phoneNumber: Yup.string()
      .required('El número de teléfono es requerido. ')
      .matches(DIGIT_PATTERN, {
        message: 'Solo se permiten dígitos. ',
        excludeEmptyString: true,
      })
      .length(8, 'El número debe ser de 8 dígitos. '),

    // Offer validation
    offer: Yup.number().required().min(1, 'Requerido.'),
  });

  const initialValues = {
    phoneNumber: '',
    offer: 1,
    promoCode: '',
  };

  const validate = makeValidate(schema);
  const required = makeRequired(schema);

  const onSubmit = (values) => {
    // TODO implementar esto
    console.log(values);
  };

  return (
    <Layout
      headerProps={{
        fixedHeaderBg: true,
        disableLogin: true,
        landingOnly: true,
        NavigationOptions: <RechargeNavLinks/>,
      }}
    >
      <SEO title="Recargar Cubacel" />

      <ServiceHeading
        image={RechargeImage}
        title="Recarga Cubacel"
        subtitle="Recarga doble al saldo principal"
        actionLabel="Recargar ahora"
      >
        <p>
          Recargando 500 CUP, en Cuba reciben 1000 CUP. Se duplicará el dinero
          recargado en el saldo principal.*
        </p>

        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form
              id="rechargeForm"
              noValidate
              onSubmit={handleSubmit}
            >
              <TextField
                id="phoneNumber"
                key="phoneNumber"
                name="phoneNumber"
                label="Número de teléfono"
                inputProps={{ maxLength: 8 }}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+53</InputAdornment>
                  ),
                }}
                required={required.phoneNumber}
              />

              <Select
                id="offer"
                key="offer"
                name="offer"
                label="Ofertas"
                fullWidth
                required={required.offer}
                displayEmpty
              >
                {fakeAmounts.map((item, index) => (
                  <MenuItem key={`amount-${index}`} value={item.id}>
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="fw-bold">{item.title}</div>
                        <div className="fw-bold text-decoration-line-through text-muted text-end">
                          ${item.previousValue}
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="fw-normal small">{item.description}</div>
                        <div className="fw-bold text-end">${item.value}</div>
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Select>

              <TextField
                id="promoCode"
                key="promoCode"
                name="promoCode"
                label="¿Tienes un código promocional?"
                fullWidth
              />
            </form>
          )}
        />
      </ServiceHeading>

      <Container className="fs-5 mt-4 mt-lg-8 px-md-7">
        <h1 className="text-success mb-4">
          La mejor promoción de recargas a Cuba
        </h1>
        <p>
          <b>
            Con Islacel puedes hacer tu primera recarga a Cuba por sólo $19.99.
          </b>{' '}
          En Islacel recargar celulares a Cuba es bien sencillo. Disfruta de las
          mejores ofertas al darte de alta y de la facilidad de recargar vía
          WhatsApp.
        </p>
        <p>
          (*) A todas las recargas “Duplica tu saldo”, realizadas del 26 de
          enero de 2022 a las 3:30 pm (hora de Cuba) al 3 de febrero de 2022 a
          las 11:59 pm (hora de Cuba), se les duplicará el dinero recargado en
          el saldo principal (por una recarga de 500 CUP, reciben 1000 CUP en el
          saldo principal).
        </p>
        <p>
          <a href="#">Saber más</a>
        </p>
      </Container>

      <Container className="mt-4 mt-md-0">
        <hr className="d-none d-md-block my-4"/>
      </Container>
      
      <CtaSection />

      <ContainerWrapper className="bg-white py-4 py-md-5 mb-5">
        <Container>
          <Row className="align-items-start">
            <AdjustableCol md className="d-none d-md-block position-relative text-center">
              <MobileImage src={MobileImageSrc} />
            </AdjustableCol>
            <Col md>
              <h2 className="text-success">¿Qué son las recargas a Cuba?</h2>
              <p>
                Las Recargas a Cuba son la forma de pagar el servicio de
                telefonía celular de tus familiares y amigos en la isla desde el
                exterior. Con Islacel puedes recargar desde cualquier país del
                mundo. Envía tus recargas a Cuba desde España, Italia, Alemania,
                México, Colombia, Brasil, Ecuador y más. Para tu comodidad,
                disponemos de pago en efectivo o tarjetas prepagadas en varios
                países de Latinoamérica.
              </p>

              <h2 className="text-success">Recargas Cubacel con bono</h2>
              <p>
                En Islacel, te ofrecemos regularmente ofertas especiales de
                recarga a Cuba. Estas promociones especiales incluyen bonos en
                saldo extra (recarga doble o recarga triple de saldo), minutos,
                SMS o GB de datos para navegar en internet.
              </p>

              <h2 className="text-success">La red 4G en Cuba</h2>
              <p>
                Ya hay 4G LTE en las cabeceras provinciales de Cuba, la red más
                rápida, estable y segura. Con el saldo de la recarga, ellos
                podrán contratar internet 4G en su celular, ideal para
                videollamadas de gran calidad y utilización de las redes
                sociales.
              </p>

              <p>
                <Button className="shadow-none">Recargar ahora</Button>
              </p>
            </Col>
          </Row>
        </Container>
      </ContainerWrapper>

      <HelpCenterLayout
        title="¿Cómo recargar celulares cubanos desde la App?"
        image={EmoteImage}
      >
        <ol className="ol-color">
          <li>Descarga el app de Cuballama para iPhone o Android.</li>
          <li>Si ya tienes una cuenta Cuballama, entra al app con tus credenciales. Si no, crea una nueva.</li>
          <li>Pincha en el ícono RECARGAS que encontrarás en el menú inferior de la app. Luego pincha en RECARGA CELULAR.</li>
          <li>Completa los datos de tu contacto (necesitarás saber el número del celular de tu familiar que luce así en el caso de Cuba: 53 5 XXX XXXX), selecciona la cantidad a recargar y pincha en el botón AÑADIR RECARGA AL CARRITO.</li>
          <li>Sigue las instrucciones de la app para proceder al pago</li>
        </ol>
      </HelpCenterLayout>

      <FaqAccordion
        leftCol={faqLeft}
        rightCol={faqRight}
      />

      <ContactSection/>
    </Layout>
  );
};

export default CubacelRechargePage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
